import React from 'react'
import { Container } from '../../components/common/AltCard'
import { SignUp } from '../../components/partner/SignUp'
import { HowItWorks } from '../../components/partner/HowItWorks'
import { Partners } from '../../components/partner/Partners'
import { Pakam } from '../../components/partner/Pakam'
import { LongCard } from '../../components/partner/LongCard'
import { PartnerCard } from '../../components/partner/PartnerCard'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const BPartner = () => {
  return (
    <HelmetProvider>
    <div>
    <Helmet>
          <script>
            {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '1250847932937384');
              fbq('track', 'PageView');
            `}
          </script>

          <noscript>
            {
              `
              <img
                height="1"
                width="1"
                style={{ display: 'none' }}
                src="https://www.facebook.com/tr?id=1250847932937384&ev=PageView&noscript=1"
                alt="facebook-pixel"
              />              
              `
            }

          </noscript>
        </Helmet>
      <>
      <div className="bg-secondary">
        <Container>
          <Partners />

        </Container>
      </div>
      <div>
        <Container>
          <HowItWorks />

          <div className="ml-20 ">
            <img
              src="/img/31021806_coin_bg280-removebg-preview 5.png"
              width="100px"
              height="100px"
              className='object-contain'
            />
          </div>
          <SignUp />
          <Pakam />
          <LongCard />
          <div className='py-4 lg:py-8'>
            <PartnerCard />            
          </div>

        </Container>
      </div>
    </>
    </div>
  </HelmetProvider>

  )
}

export default BPartner

