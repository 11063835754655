import React from 'react'
import { Container } from '../common/AltCard'
    type BoxProps = {
        text: string;
        imgUrl: string;
      };
const BoostReferral = () => {

      
      const Box = ({ text, imgUrl }: BoxProps) => {
        return (
          <div className='bg-white rounded-md shadow-md w-full p-3 flex items-center gap-3'>
            <img src={imgUrl} alt="" />
            <span className='text-[14px] font-semibold text-[#2D2D2D]'>{text}</span>
          </div>
        );
      };
  return (
    <Container>
        <div className='bg-[#F8F9FF] rounded-xl flex flex-col-reverse md:flex-row mt-10 md:mt-0'>

            <div className='mt-10 space-y-6'>
                <img src="/img/reff2.png" alt="" />
                <div className='flex justify-center pb-6'>
                  <button className='md:hidden py-4 px-8 rounded-md bg-primary text-white font-bold'>Learn more</button>
                </div>
            </div>
            <div className='min-h-[20rem] my-auto '>
                <div className='space-y-4'>
                    <div>
                        <h2 className='font-int text-center md:text-left font-[700] text-[28px]'>Boost Your Business, Sustainably</h2>
                        <p className='font-int text-center md:text-left text-[#2D2D2D]'>Learn how Pakam's innovative solutions can transform your delivery business</p>

                    </div>

                    <div className='w-full flex justify-center md:justify-normal'>

                    <div className='grid grid-cols-1 md:grid-cols-2 gap-6 w-[80%]  md:w-full'>
                        <Box imgUrl='/img/Icon.png' text='Zero waste, zero hassle'/>
                        <Box imgUrl='/img/Icon (1).png' text='Increased earnings'/>
                        <Box imgUrl='/img/Icon (2).png' text='Dedicated support'/>
                        <Box imgUrl='/img/Icon (3).png' text='Sustainable practices'/>

                    </div>                   
                     </div>



                    <button className='hidden md:block  py-4 px-8 rounded-md bg-primary text-white font-bold'>Learn more</button>                    
                </div>

            







            </div>
        </div>
    </Container>
  )
}

export default BoostReferral
