import React from 'react'
import ReferalFom from '../../components/referrals/ReferalFom'
import BoostReferral from '../../components/referrals/BoostReferral'


const Referral = () => {
  return (
    <div className='space-y-[4rem]'>
      <ReferalFom/>
      <BoostReferral/>
    </div>
  )
}

export default Referral
